<template>
  <div class="admin-tech-support">
    <h1 class="admin-tech-support__title">{{ $t('support-requests') }}</h1>
    <div class="admin-tech-support__content content">
      <div class="content-search">
        <ui-input search :placeholder="$t('search')" v-model="search" />
        <div class="content-search__icons">
          <img @click="visibleFilter = !visibleFilter" :src="require(`@/assets/svg/search/${filterColor}-filter.svg`)" alt="">
          <ui-menu v-model="visibleSort">
            <template #activator>
              <img :src="require(`@/assets/svg/search/${sortColor}-sort.svg`)" alt="">
            </template>
            <template #body>
              <div class="sort">
                <p
                    @click="changeSort(item)"
                    v-for="(item, key) in sortData"
                    :class="['sort__item', { active: item.value === sortBy.value }]"
                    :key="key"
                >
                  {{ item.text }}
                </p>
              </div>
            </template>
          </ui-menu>
        </div>
      </div>
      <div class="content-body">
        <p v-if="!visibleLoader" class="content-body__title">{{ $t('requests-length-2', {from: getRequests.length, to: total}) }}</p>
        <ui-loader v-model="visibleLoader" />
        <div v-if="!visibleLoader">
          <div  class="content-body__cards">
            <tech-support-card v-for="(item, key) in getRequests" :data="item" :key="key" />
          </div>
        </div>
        <ui-button v-if="visibleAction && !visibleLoader" color="white" @click="loadMore">
          {{ $t("show-more") }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "index",

  components: {
    TechSupportCard: () => import('@/components/template/admin/TechSupportCard.vue'),
    UiInput: () => import('@/components/ui/UiInput'),
    UiLoader: () => import('@/components/ui/UiLoader'),
    UiButton: () => import('@/components/ui/UiButton'),
    UiMenu: () => import('@/components/ui/UiMenu'),
  },

  data() {
    return {
      search: '',
      total: 0,
      visibleFilter: false,
      visibleLoader: true,
      visibleSort: false,
      visibleAction: true,
      sortBy: '',
      sortData: [
        { text: this.$t('date'), value: 'date' },
        { text: this.$t('number-2'), value: 'number' },
        { text: this.$t('type-2'), value: 'type' }
      ],
    }
  },


  computed: {
    ...mapGetters(['getRequests']),
    filterColor() {
      return this.visibleFilter ? 'red' : 'black'
    },

    sortColor() {
      return this.visibleSort ? 'red' : 'black'
    },
  },

  methods: {
    ...mapActions(['sendRequests']),
    changeSort(val) {
      this.sortBy = val
      this.visibleSort = false
    },
    loadMore() {
      this.visibleLoader = true
      this.sendRequests({skip: 0, take: this.getRequests.length + 20}).then(() => {
        setTimeout(() => {
          this.visibleLoader = false
        },500)
        if (this.getRequests.length === this.total) {
          this.visibleAction = false
        }
      })
    }
  },


  mounted () {
    this.sendRequests({skip: 0, take: 20}).then((res) => {
      this.total = res.meta.total
      if(res.data.length <= 19) {
        this.visibleAction = false
      }
    })
    setTimeout(() => {
      this.visibleLoader = false
    },500)
  }
}
</script>

<style lang="scss" scoped>
.admin-tech-support {
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  display: flex;
  justify-content: space-between;
  gap: 70px;
  min-height: 100vh;

  @media (max-width: 1200px) {
    gap: 100px;
    padding: 104px 21px 41px 19px;
  }

  @media (max-width: 1080px) {
    gap: 30px;
    flex-direction: column;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
    white-space: nowrap;
    cursor: pointer;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content-search {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #fff;

  @media (max-width: 992px) {
    gap: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 0;
    border: none;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 992px) {
      gap: 10px;
    }

    img {
      cursor: pointer;
    }
  }
}

.content-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1000px;
  width: 100%;

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      row-gap: 20px;
    }
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
}
</style>